import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import ThreeTwo from "../components/images/three-two";

// Styles
import { linkColor } from "../components/styles/colors";

const Row = styled.div`
  display: grid;
  grid-gap: 25px;

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1399px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  &:hover .project h3 {
    color: ${linkColor};
  }

  // &:hover .project {
  //   opacity: 0.5;
  // }

  &:hover .project:hover h3 {
    color: #000;
  }

  // &:hover .project:hover {
  //   opacity: 1;
  // }
`;

const Page = styled.div`
  padding: 56px 20px 20px 20px;

  font-size: 15px;

  & h3 {
    font-size: 15px;
  }
`;

const ResearchContainer = styled.div`
  & h3 {
    font-size: 15px;
    text-align: center;

    margin-top: 5px;
  }

  // &:hover h3 {
  //   color: ${linkColor};
  //   @media (hover: none) and (pointer: coarse) {
  //     font-style: normal;

  //     & em {
  //       font-style: italic;
  //     }
  //   }
  // }
`;

const Research = ({ data: { allPrismicResearch } }) => {
  const researchGrid = allPrismicResearch.edges.map(research => (
    <div col={12} md={4} lg={3} key={research.node.prismicId}>
      <ResearchContainer className="project">
        <Link to={`/research/${research.node.uid}`}>
          <ThreeTwo
            image={research.node.data.thumbnail.url}
            alt={research.node.data.thumbnail.alt}
            lazy={true}
            size={500}
            objectFit={
              research.node.data.thumbnail.dimensions.height >
              research.node.data.thumbnail.dimensions.width
                ? "contain"
                : "cover"
            }
          />
          <div
            dangerouslySetInnerHTML={{
              __html: research.node.data.title.html,
            }}
          />
        </Link>
      </ResearchContainer>
    </div>
  ));

  return (
    <Page>
      <Row>{researchGrid}</Row>
    </Page>
  );
};

export default Research;

export const pageQuery = graphql`
  {
    allPrismicResearch {
      edges {
        node {
          prismicId
          uid
          data {
            thumbnail {
              url
              alt
              dimensions {
                width
                height
              }
            }
            title {
              html
            }
          }
        }
      }
    }
  }
`;
